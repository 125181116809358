(function($) {

    $(document).ready(function() {

        var $body   = $('html, body'),
            $window = $(window);

        /**
         * EMBED-RESPONSIVE
         * Add resposive-embed to embed contents
         */
        $('.entry-content').find('iframe, embed, object').each(function(){
            $(this).wrap( '<div class="embed-responsive embed-responsive-16by9"></div>' );
        }); // embed-responsive */

        /**
         * TABLE-RESPONSIVE
         * Add resposive-table to table
         */
        $('.entry-content').find('table').each(function(){
            $(this).wrap( '<div class="table-responsive"></div>' );
            $(this).addClass( 'table' );
        }); // table-responsive */

        /**
         * EXTERNAL LINKS
         * Automatically creates a external link icon
         * on externall links
         */
        $('body').find('a[href*="http"]').each(function(){
            if (this.hostname !== location.hostname && !$(this).hasClass('skip-external')) {
                $(this).attr('rel', 'external');
                $(this).append(' <span class="sr-only">Site externo</span>');
            }
        }); // external links */

        /**
         * SOFT SCROLL
         * return soft sroll to the anchor
         */
        $('.header-lg-wrp, .header-lg-btn-holder, .header-xs-elements, .finish, .page-custom-c2a').on( 'click', 'a[href*="#"]:not([href="#"])', function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    var offsetTop = target.offset().top - 70;
                    if ( $window.width() > 992 ) {
                        offsetTop = target.offset().top - 100;
                    }
                    console.log( offsetTop );
                    $('html, body').animate({ scrollTop: offsetTop}, 1000);
                    return false;
                }
            }
          }); // back-top-top */

        /**
         * POP-UP SHARE
         * Open share options on a pop-up window
         */
        $('.entry-share a').on('click', function(e) {
            var $h = 510, $w = 510,
                px = Math.floor( ((screen.availWidth || 1024) - $w) / 2 ),
                py = Math.floor( ((screen.availHeight || 700) - $h) / 2 );
            var popup = window.open( $(this).attr('href'), 'social',
                'width='+$w+',height='+$h+',left='+px+',top='+py+
                ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');
            if ( popup ) {
                popup.focus();
                e.preventDefault();
            }
            return !!popup;
        }); // pop-up share */

        /**
         * FILTER
         */
        $('[data-filter]').each( function() {
            var $trggrs = $(this).find('[data-filter-value]');
            var $target = $($(this).attr('data-filter'));
            $trggrs.on('click', function(e) {
                e.preventDefault();
                if ( $(this).parent().hasClass('active') ) {
                    return false;
                }
                $trggrs.parent().removeClass('active');
                $(this).parent().addClass('active');
                filter_value = $(this).attr('data-filter-value');
                $target.find('li').slideUp('slow', function() {
                    if ( filter_value ) {
                        $target.find('li.'+filter_value).slideDown('slow');
                    } else {
                        $target.find('li').slideDown('slow');
                    }
                });
            });
        }); // entry-siblings */

        /**
         * ENTRY-SIBLINGS
         */
        $('.entry-siblings-title[href]').on('click', function(e) {
            e.preventDefault();
            $(this).parent().find('.entry-siblings-lists').toggleClass('collapsed');
        }); // entry-siblings */

        /*
         * IMG SVG INLINE
         * Replace all SVG images with inline SVG
         */
        $('img[width=1][height=1]').each(function(){
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = $(data).find('svg');
                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }
                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');
                // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }
                // Replace image with new SVG
                $img.replaceWith($svg);
            }, 'xml');
        }); // img-svg-inline */

        /*!
         * FEATURED SIZE
         * Resize featured to same window size.
         */
        function alignDestaques() {
            $('.widget-featureds').each(function() {
                var item = $(this).find('.carousel-inner .item');
                console.log(item);
                if ($window.width() < 768) {
                    return false;
                }
               item.css( 'height', ( $window.height() - 101 ) );
            }); //.section-carousel
        }
        $window.on('resize', function() {
            alignDestaques();
        });
        alignDestaques();
        //  highlights.slide */

        /**
         * SUGGESTION VALUES
         * Insert suggestion values to input
         */
        $('.form-suggestion-btn').click(function() {
            $value = $(this).attr('value');
            $('.form-suggestion-btn').removeClass('active');
            $(this).addClass('active');
            $('#ae_value').attr('value', $value);
        });
        $('#ae_value').change(function() {
            $('.form-suggestion-btn').removeClass('active');
        }); // suggestion-values */

        /**
         * FORM MASKS
         * Create the masks for all form inputs
         */
        $('.mask_date').mask('00/00/0000');
        $('.mask_time').mask('00:00', {reverse: true});
        $('.mask_cpf').mask('000.000.000-00', {reverse: true});
        $('.mask_rg').mask('00.000.000-0');
        $('.mask_cep').mask('00000-000');
        $('.mask_tel').mask('(00) 0000-0000');
        $('.mask_currency').mask("#.##0,00", { reverse: true, });
        var SPMaskBehavior = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };
        $('.mask_cel').mask(SPMaskBehavior, spOptions);
        // form masks */

    }); // document.ready

    /**
     * FACEBOOK CONNECT
     * Load Facebook's share button
     */
    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v2.7&appId=1716856078603108";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    // facebook connect */

})(jQuery);
